import { getStock, exportStock } from "./api.js";
import navList from "../navList";
import Util from "@/utils/utils";

export default {
  data() {
    return {
      expLoading: false,
      pageLoadFlag: false,
      navList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/stockCenter/unsettledReservation",
          name: "unsettledReservation",
          title: "未结预留"
        }
      ],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10,
        sortName: "",
        sortOrder: ""
      },
      count: {
        totalOccQty: 0,
        totalActQty: 0,
        totalAvaQty: 0
      },
      scroll: {
        // x: 'true',
        // y: 400
      },
      // showTotalColumns: [ "预留数量"],
      columns: [
        {
          label: "型号",
          prop: "model",
          key: "model",
          // sortable: "custom",
          // sortName: "model",
          width: 180
        },
        {
          label: "颜色",
          prop: "colour",
          key: "colour",
          width: 120
        },
        {
          label: "销售组织",
          prop: "orgName",
          key: "orgName",
          width: 120
        },
        {
          label: "仓库",
          prop: "gicWarehouseName",
          key: "gicWarehouseName",
          width: 120
        },
        {
          label: "定价组",
          prop: "priceGroupName",
          key: "priceGroupName",
          width: 150
        },
        {
          label: "预留的单号",
          prop: "documentNum",
          key: "documentNum",
          slot: "documentNum",
          width: 150
        },
        {
          label: "预留时间",
          prop: "createDate",
          key: "createDate",
          width: 150
        },

        {
          label: "物料组",
          prop: "materialGroupName",
          key: "materialGroupName",
          width: 100
        },
        {
          label: "质量等级",
          prop: "quvlityLv",
          key: "quvlityLv",
          width: 120
        },
        {
          label: "库存状态",
          prop: "invStatusName",
          key: "invStatusName",
          width: 120
        },
        {
          label: "库存类别",
          prop: "invTypeName",
          key: "invTypeName",
          width: 120
        },

        {
          label: "补差类型",
          prop: "invStatusTypeName",
          key: "invStatusTypeName",
          width: 120
        },

        {
          label: "预留数量",
          prop: "bigQtyLock",
          key: "bigQtyLock",
          align: "right",
          width: 120
        }
      ],
      data: [],
      dataAll: [],//全部数据
      terms: {},
      taoji: true,
      danji: false,
      showLoading: false
    };
  },
  computed: {
    // 实际统计
    totalBig: function () {
      return this.data.reduce((sum, item) => {
        return sum + (parseInt(item.totalBig) || 0);
      }, 0);
    },
    // 可用统计
    bigQty: function () {
      return this.data.reduce((sum, item) => {
        return sum + (parseInt(item.bigQty) || 0);
      }, 0);
    },
    // 预留统计
    bigQtyLock: function () {
      return this.data.reduce((sum, item) => {
        return sum + (parseInt(item.bigQtyLock) || 0);
      }, 0);
    }
  },
  created() {
    this.navList = navList.nav1;
    this.getData();
  },
  methods: {
    // 点击订单号跳转
    linkClick(documentNum) {
      let path = '',
        filter = '';
      if(documentNum.indexOf('S') != -1){//S单挑销售订单列表
        path = "/channelOrder/orderList";
        filter = 'documentNum:' + documentNum;
      }
      else if(documentNum.indexOf('PR') != -1){ //R PR跳退货明细
        path = "/channelReturns/distributorReturnList";
        filter = 'documentNum:' + documentNum;
      }else if(documentNum.indexOf('R') != -1){ //R 跳退货明细
        path = "/hisenseReturn/defectiveReturnListNew";
        filter = 'serialCode:' + documentNum;
      }
      else{//C单跳O单库存调整明细
        path = "/stockCenter/stockAdjustment";
        filter = 'documentNum:' + documentNum;
      };
     
      let routeUrl = this.$router.resolve({
        path: path,
        query: {
          filter: filter,
          onlyUserParam: true
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    taojiChang(type) {
      if (type == "taoji") {
        this.danji = !this.taoji;
      } else {
        this.taoji = !this.danji;
      }
      this.pager.pageNo = 1;
      this.getData();
    },
    searchFun(arg) {
      this.terms = arg[1];
      this.pager.pageNo = 1;
      this.getData();
    },
    //排序change事件
    onTableChange(sorter) {
      const sortName = this.columns.filter(it => it.prop == sorter.prop);
      if (sortName.length < 1) {
        return;
      }
      if (sorter && sorter.order) {
        this.pager.sortOrder =
          sorter.order == "ascending"
            ? "asc"
            : sorter.order == "descending"
              ? "desc"
              : "";
        this.pager.sortName = sortName[0].sortName;
      } else {
        this.pager.sortOrder = "";
        this.pager.sortName = "";
      }
      this.getData();
    },
    exportFile() {
      this.expLoading = true;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        _loading: true,
        terms: {
          model: "",
          colour: "",
          warehouseId: "",
          invStatusId: "",
          isLock: "",
          dealerMaterialGroupFlag: "",
          materialGroupCode: "",
          materialGroupName: "",
          gicWarehouseType: "",
          invStatusType: "",
          invType: "",
          bigQtyMin: "",
          ...this.terms
        },
        page: {
          pageNo: 1,
          pageSize: 300000,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        },
        loginType: "CS"
      };
      const url = this.taoji ? 'exportNotReleasedReserveList&type=1' : 'exportNotReleasedReserveList&type=1'
      exportStock(data, url).then(res => {
        Util.blobToJson(res.data).then(content => {
          if (content && content.msg == 'success') {
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err => {
          Util.downloadFile(res.data, '未结预留.xls')
        }).finally(() => {
          this.expLoading = false;
        })
      }).catch(err => {
        this.expLoading = false;
      });
    },
    getData() {
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        _loading: true,
        terms: {
          model: "",
          orgCode: "",
          materialGroupCode: "",
          materialGroupName: "",
          warehouseId: "",
          gicWarehouseType: "",
          invType: "",
          invStatusId: "",
          invStatusType: "",
          bigQtyMin: "",
          bigQtyLockMin: "",

          ...this.terms
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        },
        loginType: "CS"
      };
      this.showLoading = true
      const url = this.taoji ? 'findNotReleasedReserveList' : 'findNotReleasedReserveList'
      getStock(data, url).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.dataAll = data.data || [];
        const start = (this.pager.pageNo - 1) * this.pager.pageSize;
        data.data = data.data || [];
        this.data = data.data.slice(start, start + this.pager.pageSize);
        this.pager.count = data.data.length;
      }).finally(() => {
        this.showLoading = false
      });
    },
    //前端分页
    pageChangeGetData() {
      const start = (this.pager.pageNo - 1) * this.pager.pageSize;
      this.data = this.dataAll.slice(start, start + this.pager.pageSize);
    }
  }
};
